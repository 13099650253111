import { getLocalStorage, replaceHrefSpecChar } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { abtservice } from 'public/src/services/abt'
import { posKeys, newPosKeys } from '../config'
import { promotionCenterNew } from 'public/src/pages/common/promotion/indexNew'
import { shippingCenter } from 'public/src/pages/common/shipping/index.js'
import { getSiteFreeMallApi } from 'public/src/services/api/cartAddItem.js'
import ProductRecommend from 'public/src/services/productRecommend/index.js'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'

const { langPath } = gbCommonInfo

const getSiteFreeMall = async () => {
  let cache = null
  const addressCookie = getLocalStorage('addressCookie')
  if (addressCookie) {
    try {
      cache =  typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      console.log('getSiteFreeMall', error)
    }
  }
  const result = await getSiteFreeMallApi({
    countryId: cache?.countryId
  })
  if (result?.data?.code == 0) {
    return result?.data?.info?.cart_total_free?.is_free_shipping === 1
  }
  return false
}

/**
 * 更新rootState
 */
export async function updateRootStateFreeShipping(cartInfo, callback) {
  let isFreeShipping = false
  // 有购物车数据时，才去请求免邮方式
  if (typeof window !== 'undefined' && cartInfo?.carts?.length) {
    isFreeShipping = await getSiteFreeMall()
  }
  if (callback) {
    callback(isFreeShipping)
  } else {
    window?._gb_app_?.$store?.commit('changeFreeShippingState', { isFreeShipping })
    window?._gb_cart_tag_tips_?.update()
  }
}

/**
 * 更新rootState
 */
export function updateRootStateCartInfo({ carts = [], sum = 0, info = {} } = {}) {
  window._gb_app_?.$store?.commit('changeCartInfo', { carts, sum, info })
}

/**
 * 获取购物车内容
 */
export const fetchCart = () => {
  return schttp({
    url: '/api/cart/checkcart/get',
    params: {
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  })
}

/**
 * 批量删除
 * @param {Array, String, Number} ids 需要删除的商品的cartId
 */
export const batchDelete = ({ ids = [], filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchDelete/update',
    params: {
      ids: batchIds.join(',')
    },
    data: {
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if ( res.code == 0 && res.info ) {
      if (filterCheck == 1 && res.info?.carts?.length) {
        // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
        res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
      }
      updateRootStateCartInfo({ carts: res.info.carts, sum: res.info.cartSumQuantity, info: res.info })
    }
    return res
  })
}

/**
 * 批量收藏
 * @param {Array, String, Number} ids 需要收藏的商品的cartId
 */
export const batchWishGoods = ({ ids = [], filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchWishGoods/update',
    method: 'POST',
    data: {
      ids: batchIds,
      'ids[]': batchIds, // 兼容写法 上线后可移除
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if ( res.code == 0 && res.info ) {
      if (filterCheck == 1 && res.info?.carts?.length) {
        // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
        res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
      }
      updateRootStateCartInfo({ carts: res.info.carts, sum: res.info.cartSumQuantity, info: res.info })
    }
    return res
  })
}

/**
 * 更新购物车数量
 * @param {String} quantity 数量
 * @param {String} id 购物车id
 */
let updateAbortController
export const updateCartItem = ({ quantity, id }) => {
  updateAbortController?.abort()
  updateAbortController = new SchttpAbortCon()
  return schttp({
    url: '/api/cart/update',
    method: 'POST',
    data: {
      quantity,
      id,
      cart_prime_product_code: window.cart_prime_product_code || ''
    },
    signal: updateAbortController?.signal
  })
}

/**
 * 删除商品
 * @param {String} id 购物车id
 */
export const deleteCartItem = ({ item }) => {
  return schttp({
    url: '/api/cart/delete',
    method: 'POST',
    data: {
      id: item.id,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  })
}

/**
 * 获取优惠券
 */
export const getCouponListForCart = () => {
  return schttp({
    url: '/api/coupon/getNewCouponListForCart/get',
    params: {
      isOldVersion: 0
    }
  })
}

/**
 * @description 获取店铺是否配置优惠券
 * @param {Object} data
 * @returns {Promise}
 */
export const getStoreCouponState = (data) => {
  return schttp({
    url: '/api/coupon/getStoreCouponState/query',
    method: 'POST',
    data: data
  })
}

/**
 * @description 获取店铺优惠券
 * @param {Object} data
 * @returns {Promise}
 */
export const getCouponListStore = (data) => {
  return schttp({
    url: '/api/coupon/getCouponListStore/get',
    method: 'POST',
    data: data
  })
}

/**
 * 获取未支付订单数据
 *
 * @export
 * @return {Promise}
 */
export function getUnPaidOrdersInfo() {
  return schttp({
    url: `/api/orders/base/getOrderListByStatus/query`,
    params: {
      statusType: 1
    },
  }).then(res => {
    return res || {}
  })
}

/**
 * 通过选品id，获取选品链接
 *
 * @export
 * @param {string} [scid='']
 * @return {Promise}
 */
export function getSelectInfoByScId(scid = '') {
  return schttp({
    url: '/api/productInfo/selectInfoByScId/get',
    params: {
      sc_id: scid
    }
  }).then(res => {
    const scInfo = res?.info?.scInfo || []
    if ( scInfo && scInfo.length ) {
      const {
        sc_url_id,
        select_name,
        select_type_name,
        sc_url
      } = res.info.scInfo[0]
      let url = ''
      if ( sc_url ) {
        url = `${ langPath }${ sc_url }`
      } else if ( sc_url_id && select_name && select_type_name ) {
        url = `${ langPath }/${ select_type_name }/${ select_name }-sc-${ sc_url_id }.html`
      }
      return replaceHrefSpecChar({ href: url })
    } else {
      return ''
    }
  })
}

/**
 * 查询库存
 * @return {Promise<unknown>}
 */
export function checkStock({ needLoginStatus = true } = {}) {
  return schttp({
    url: '/api/cart/checkStock/get',
    params: {
      needLoginStatus: needLoginStatus
    }
  })
}

/**
 * 请求购物车ABT
 * @return {Promise<unknown>}
 */
export function queryCartABT() {
  // eslint-disable-next-line @shein-aidc/abt/abt
  return abtservice.getUserAbtResult({ posKeys: posKeys.join(','), newPosKeys: newPosKeys.join() })
}

/**
 * 请求购物车促销详情
 * @returns 
 */
export function queryCartPromotion({ carts = [] } = {}) {
  let needRequestIds = []
  carts.forEach(item => {
    let promotionIds = item.product.product_promotion_info?.map(promotion => promotion.promotion_id) || []
    needRequestIds = needRequestIds.concat(promotionIds)
  })
  needRequestIds = [...new Set(needRequestIds)]
  if (!needRequestIds.length) return Promise.resolve()
  return promotionCenterNew.getIntegrityPromotionInfo({ ids: needRequestIds })
}

/**
 * 
 * 请求免邮方式
 * @returns 
 */
export function fetchMallShippingInfo({ cartInfo, originMallShippingInfo = null }) {
  let mallList = cartInfo?.mall_list || []
  let shippingInfoParams = []
  mallList.forEach(item => {
    let cart_list = cartInfo?.carts?.filter(cartItem => cartItem.mall_code == item.mall_code && cartItem.is_checked == 1)
    let mallInfo = {
      mall_code: item.mall_code,
      is_free_activity_limit: item.is_free_activity_limit,
      free_shipping_product_price: item.free_shipping_product_price?.amount,
      total_price: item.total_price?.amount,
      skc_list: cart_list.map(cartItem => cartItem.product.goods_sn)
    }
    shippingInfoParams.push(mallInfo)
  })

  return shippingCenter.getMallShippingContent({ mall: shippingInfoParams, mallShippingInfo: originMallShippingInfo })
}


export function getSocialShareInfo({ goods_info, bff = false, shareId, shareType }) {
  if (bff) {
    const { currency, appLanguage } = window.gbCommonInfo
    // 此处app中间层要求请求体格式为formData
    const formData = new FormData()
    formData.append('shareId', shareId)
    formData.append('shareType', shareType)

    return schttp({
      method: 'POST',
      url: '/social/share/info',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'AppCurrency': currency,
        'AppLanguage': appLanguage
      },
      useBffApi: true
    })
  }
  return schttp({
    url: `/api/cart/socialShareInfo/get`,
    method: 'POST',
    data: {
      goods_info,
    }
  })
}

export function getProductsCartSharing(params = {}) {
  return schttp({
    url: `/api/cart/getProductsCartSharing/get`,
    method: 'POST',
    data: {
      goodsArr: params.goodsArr,
    }
  })
}

export function fetchProductRecommend(product) {
  if (!product) return
  const { cat_id, goods_id } = product

  const service = new ProductRecommend({
    abtParamsMatchConfig: [
      {
        type: 'emarsys',
        matchRule: /^emarsys_/,
      },
      {
        type: 'request',
        matchRule: /^is_pde=3/,
        method: 'POST',
        url: '/api/recommend/facadeAtom/get',
        atomic: true, // 商品接口才需要
        params: {
          pageEnable: 0,
          scene_id: 18,
          limit: 10,
          rule_id: /rule_id=([^&]+)/,
          contextParams: {
            goods_list: [{
              cate_id: cat_id,
              goods_id: goods_id
            }]
          },
        }
      }
    ],
  })
  
  return service.doRecommend({
    posKey: 'Similaritems',
    pageKey: 'page_find_similar',
    rowNum: 3,
  })
}

export const getTaxTip = (params) => {
  return schttp({
    url: '/api/cart/getIncludeTaxInfo/get',
    method: 'POST',
    data: {
      ...params
      // outside params
      // 'lang': 'en', 
      // "siteUid": "pwus"
    }
  })
}

export function fetchAtomicInfo(products, fields) {
  return itemSer.getAtomicInfo({
    goods: products,
    needAssign: true,
    fields: fields,
    pageKey: 'page_cart'
  })
}

// 批量查询用户信息
export function getMembersInfo(memberIds) {
  return schttp({
    url: '/api/cart/batchMemberInfo/get',
    method: 'POST',
    data: {
      memberIds,
    }
  })
}

export async function queryLatestUnPaidOrderInfo() {
  const formatUnPaidOrderInfo = (orderInfo, isSupportCountryInfo) => {
    let orderData = {}
    if(isSupportCountryInfo?.info?.res == 1) {
      orderData = orderInfo?.info?.order_list?.find(order => order?.order_type == 1) || {}
    }
    return {
      traceId: [orderInfo?.traceId, isSupportCountryInfo?.traceId],
      orderData
    }
  }
  let data = await schttp({
    url: '/api/cart/queryLatestUnPaidOrderInfo/get',
    method: 'POST',
    data: {
      isClient: true // 自定义的参数, 标明客户端请求, 需要串行聚合两个接口
    }
  })
  return formatUnPaidOrderInfo(data.unPaidOrderInfo, data.isSuppoortCountry)
}

const getAddressInfo = () => {
  const addressCookie = getLocalStorage('addressCookie')
  let addressInfo = {}
  if (addressCookie) {
    try {
      addressInfo = typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      console.log('获取地址缓存失败', error)
    }
  }
  return addressInfo
}

/**
 * 获取赠品列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @returns 
 */
export const getGiftProductList = ({ promotion_id, range, page, limit }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId)

  return schttp({
    method: 'POST',
    url: '/promotion/gift_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
    },
    useBffApi: true
  })
}

/**
 * 获取加价购换购列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {string} param.brand 品牌code
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @returns 
 */
export const getAddBuyProductList = ({ promotion_id, range, brand, page, limit }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  brand && formData.append('brand', brand)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId)

  return schttp({
    method: 'POST',
    url: '/promotion/add_buy_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取分享购物车商品信息
 * @param {object} param
 * @param {Array} param.goods_info 分享商品信息
 */
export const getSocialShareCartGoodsInfo = ({ goods_info }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const data = {
    goods_info
  }
  return schttp({
    method: 'POST',
    url: '/social/share/cart_goods_info',
    data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取分享链接
 * @param {object} param
 * @param {string} param.pageType 分享页面类型 1 商品四级页面
 * @param {string} param.shareUrl 分享链接
 * @returns 
 */
export const getMarketingGmtcShareLink = ({ pageType, shareUrl }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('pageType', pageType)
  formData.append('shareUrl', shareUrl)

  return schttp({
    method: 'POST',
    url: '/marketing/gmtc/shareLink',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

export async function getQsTimelinessInfo() {
  const addressCookie = getLocalStorage('addressCookie')
  let addressInfo = {}
  
  // 优先取地址缓存的，拿不到再调查默认地址接口
  try {
    const addressJson = typeof addressCookie === 'string' ?  JSON.parse(addressCookie) : addressCookie
    if (addressJson?.countryId) {
      addressInfo = Object.assign({}, addressJson)
    } else {
      const defaultAddress = await schttp({
        url: '/api/cart/getDefaultAddress/get',
        method: 'GET',
      })
      addressInfo = Object.assign({}, defaultAddress?.info)
    }
  } catch (err) {
    console.log(`[cart getQsTimelinessInfo] 获取countryId失败: ${err}`)
  }

  try {
    const qsTimelinessInfo = await schttp({
      url: '/api/common/qsTimelinessInfo/get',
      method: 'POST',
      data: { ...addressInfo }
    })
    return qsTimelinessInfo
  } catch (err) {
    console.log(`[cart getQsTimelinessInfo] 获取qs信息失败: ${err}`)
    return { }
  }
}

export async function getCanceledOrderInfo(params) {
  const { currency, appLanguage } = window.gbCommonInfo
  const [res, err] = await schttp({
    method: 'POST',
    url: '/cart/order/unpaid_order_info',
    data: {
      cachedOrderIdList: params || []
    },
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  }).then(r => [r, null], e => [null, e])
  if(res.code == '0') {
    return res.info
  } else {
    return {}
  }
}
